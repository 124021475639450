import { Injectable } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { Observable } from 'rxjs';

import { tap } from 'rxjs/operators';
import { AppUserInformationDef } from 'src/app/api_interfaces/core';

@Injectable({
  providedIn: 'root'
})
export class UserAPIService {

  constructor(
    private auth: AuthService
  ) { }

  getInformation(): Observable<AppUserInformationDef> {
    return this.auth._getUserInformation();
  }

  authState(): Observable<any> {
    return this.auth._authState();
  }

  logout(): Promise<boolean> {
    return this.auth._logout();
  }

  setUserInformation(data: {
    data: {
      name?: string,
      email?: string,
      profile_picture?: string,
      phone?: string,
    } | string,
    file?: {
      blob: Blob,
      name: string
    }
  }): Observable<AppUserInformationDef> {
    data.data = JSON.stringify(data.data);
    return this.auth._post('account/change_user_information.json', data).pipe(tap({
      complete: () => this.auth._checkLogin(),
      error: () => this.auth._checkLogin()
    }));
  }

  checkLogin(): Promise<AppUserInformationDef> {
    return this.auth._checkLogin();
  }

  changePassword(data: any): Observable<boolean> {
    return this.auth._post('account/change_password.json', data);
  }

  login(data: {
    email: string,
    password: string,
    captchaToken?: string,
    captchaAction?: string
  }): Observable<AppUserInformationDef> {
    return this.auth._post('account/login.json', data);
  }

  signup(data: {
    email: string,
    password: string,
    name: string,
    organization_token?: string
  }): Observable<AppUserInformationDef> {
    return this.auth._post('account/signup.json', data);
  }

  sendPasswordResetEmail(data: {
    email: string
  }): Observable<boolean> {
    return this.auth._post('account/send_password_reset_request_email.json', data);
  }

  resetPassword(data: {
    password: string,
    token: string
  }): Observable<boolean> {
    return this.auth._post('account/reset_password.json', data);
  }

  getLoginToken(data: {}): Observable<{ token: string }> {
    return this.auth._post('account/get_login_token.json', data);
  }

  loginWithToken(data: { token: string }): Observable<boolean> {
    return this.auth._post('account/login_token.json', data);
  }

}
