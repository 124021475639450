import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { apiPathUrl, apiWithCredentials } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpsService {

  constructor(private http: HttpClient) { }

  get(endpoint: string, data?: any, headers?: HttpHeaders, options?: any): Observable<any> {
    const formData = new FormData();
    // formData.forEach();
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key]) {
          if (data[key].blob) {
            // blob name
            formData.set(key, data[key].blob, data[key].name);
          } else {
            formData.set(key, data[key]);
          }
        }
      });
      const params = [];
      formData.forEach((value: FormDataEntryValue, key: string) => params.push(encodeURIComponent(key) + '=' +
        encodeURIComponent(value as string)));
      if (params.length > 0) {
        endpoint += (endpoint.indexOf('?') >= 0 ? '&' : '?') + params.join('&');
      }
    }
    return this._makeRequest('GET', endpoint, undefined, headers, options);
  }

  post(endpoint: string, data?: any, headers?: HttpHeaders): Observable<any> {
    const formData = new FormData();
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key]) {
          if (data[key].blob) {
            // blob name
            formData.set(key, data[key].blob, data[key].name);
          } else {
            formData.set(key, data[key]);
          }
        }
      });
    }
    return this._makeRequest('POST', endpoint, formData, headers);
  }

  put(endpoint: string, data?: any, headers?: HttpHeaders): Observable<any> {
    const formData = new FormData();
    if (data) {
      Object.keys(data).forEach((key) => {
        formData.set(key, data[key]);
      });
    }
    return this._makeRequest('PUT', endpoint, formData, headers);
  }

  delete(endpoint: string, headers?: HttpHeaders): Observable<any> {
    return this._makeRequest('DELETE', endpoint, undefined, headers);
  }

  private _makeRequest(type: string = 'GET', endPoint: string, formData?: FormData, headers?: HttpHeaders, options?: any): Observable<any> {
    endPoint = `${apiPathUrl + endPoint}`;
    options = Object.assign({}, options || {}, { observe: 'response', withCredentials: apiWithCredentials });
    if (headers) {
      options.headers = headers;
    }
    let request: Observable<any>;
    if (type === 'POST') {
      request = this.http.post(endPoint, formData, options);
    } else if (type === 'PUT') {
      request = this.http.put(endPoint, formData, options);
    } else if (type === 'DELETE') {
      request = this.http.delete(endPoint, options);
    } else {
      request = this.http.get(endPoint, options);
    }
    return request.pipe(map((response: any) => {
      let error = 'SYSTEM_ERROR';
      if (response.status === 200) {
        if (!response.body.error) {
          return (response.body.result !== null && response.body.result !== undefined) ? response.body.result : response.body;
        } else {
          error = response.body.result;
        }
      }
      throw new Error(error);
    }), catchError((error: HttpErrorResponse) => throwError(error)));
  }

}
