import { Component, OnInit } from '@angular/core';
import { version } from '../../../../package.json';

@Component({
  selector: 'app-version-badge',
  templateUrl: './version-badge.component.html',
  styleUrls: ['./version-badge.component.scss'],
})
export class VersionBadgeComponent implements OnInit {
  version = version;
  constructor() { }

  ngOnInit() { }

}
