import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then( m => m.SignupPageModule),
  },
  {
    path: 'password-reset',
    loadChildren: () => import('./pages/password-reset/password-reset.module').then( m => m.PasswordResetPageModule),
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
  },
  {
    path: 'loading-oauth',
    loadChildren: () => import('./components/o-auth-handler/pages/loading-oauth/loading-oauth.module').then( m => m.LoadingOauthPageModule)
  },
  {
    path: 'sheet-view',
    loadChildren: () => import('./pages/sheet-view/sheet-view.module').then( m => m.SheetViewPageModule)
  },
  {
    path: 'sheet-view/:id',
    loadChildren: () => import('./pages/sheet-view/sheet-view.module').then( m => m.SheetViewPageModule)
  },
  {
    path: 'ticket-view',
    loadChildren: () => import('./pages/ticket-view/ticket-view.module').then( m => m.TicketViewPageModule),
  },
  {
    path: 'ticket-view/:id',
    loadChildren: () => import('./pages/ticket-view/ticket-view.module').then( m => m.TicketViewPageModule),
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
