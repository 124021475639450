import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { supportedLanguages, defaultLanguage } from 'src/environments/environment';
import { Plugins, DeviceLanguageCodeResult } from '@capacitor/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private supportedLanguages = supportedLanguages;

  private selected$: BehaviorSubject<string> = new BehaviorSubject<string>(defaultLanguage);

  constructor(private translate: TranslateService) { }

  async setInitialAppLanguage() {
    const selected = await this.getCurrentDeviceLanguage();
    console.log('Using language: ' + selected);
    this.translate.setDefaultLang(selected);
    this.setLanguage(selected);
  }

  getLanguages() {
    return this.supportedLanguages;
  }

  setLanguage(langCode: string) {
    this.translate.use(langCode);
    this.selected$.next(langCode);
  }

  async getCurrentDeviceLanguage() {
    try {
      const { Device } = Plugins;
      const language: DeviceLanguageCodeResult = await Device.getLanguageCode();
      // Detect if supported Language
      for (const lang of this.supportedLanguages) {
        if (lang.value === language.value) {
          return lang.value;
        }
      }
      return defaultLanguage;
    } catch (e) {
      return defaultLanguage;
    }
  }

}
