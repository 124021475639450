import { ApplicationRef, Injectable } from '@angular/core';
import { AppVersionDef, AppVersionCheckDef } from '../../../api_interfaces/core';
import { AlertController, Platform } from '@ionic/angular';
import { version } from '../../../../../package.json';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../core/auth.service';
import { SwUpdate } from '@angular/service-worker';
import { AppTypeDef } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemAPIService {

  public version: string = version;
  private appVersion$: BehaviorSubject<AppVersionDef> = new BehaviorSubject<AppVersionDef>({
    id: 0,
    version,
    supported: true,
    tag: AppTypeDef,
    public: true,
    data: {
      download_url: {
        ios: 'https://appstore.com/',
        android: 'https://play.google.com/store/apps/'
      }
    }
  });

  checkingMode = 'httpRequest';

  checkerInstance = null;
  checkerInterval = 60000; // one minute

  constructor(
    private alertController: AlertController,
    private platform: Platform,
    private auth: AuthService,
    private sw: SwUpdate,
    private appRef: ApplicationRef
  ) {
    // this.checkForUpdates();
  }

  initVersionChecker() {
    const subscription = this.appRef.isStable.subscribe({
      next: (isStable) => {
        if (!isStable) return;
        setTimeout(() => subscription.unsubscribe(), 500);
        /**
         * register sw methods
         * start interval checker, checking backend and sw
         */
        if (!this.sw.isEnabled) {
          // check version with interval in another mode
          this.checkingMode = 'httpRequest';
        } else {
          this.checkingMode = 'serviceWorker';
          this.sw.available.subscribe({
            next: () => {
              // an update is available, alert to user, request information to backend and alert to user
              this.sw.activateUpdate().then(() => {
                // alert to user to update to activate the new version
                this.requestUpdateInfo()
                  .then((result) => this.processUpdateInfo(result))
                  .catch(() => this._updateAvailableSwAlert());
              });
            }
          });
          //this.sw.activated.subscribe();
        }
        this.checkForUpdates();
        this._startChecker();
      }
    });
  }

  private _startChecker() {
    this.checkerInstance = setInterval(() => this.checkForUpdates(), this.checkerInterval);
  }

  private _stopChecker() {
    clearInterval(this.checkerInstance);
  }

  checkForUpdates() {
    console.log('Checking for upodates in mode: ', this.checkingMode);
    if (this.checkingMode == 'serviceWorker') {
      this.sw.checkForUpdate().then(() => {
        // Checked
      });
    } else if (this.checkingMode == 'httpRequest') {
      // make http request
      this.requestUpdateInfo().then((result) => this.processUpdateInfo(result));
    }
  }

  private requestUpdateInfo() {
    return this.auth._post('version/is_valid.json', {
      version: this.version,
      tag: AppTypeDef
    }).toPromise();
  }

  private processUpdateInfo(result: AppVersionCheckDef) {
    this.appVersion$.next(result.current_version);
    if (!result?.current_version?.supported) {
      if (result?.available_version?.id > 0) {
        this._updateRequiredAlert(
          result.available_version.version,
          this.platform.is('ios') ? result?.available_version?.data?.download_url?.ios : result?.available_version?.data?.download_url?.android, this.platform.is('desktop'));
      }
    } else if (result.available_version && result.available_version.public) {
      this._newVersionAlert(
        result.available_version.version,
        this.platform.is('ios') ? result?.available_version?.data?.download_url?.ios : result?.available_version?.data?.download_url?.android, this.platform.is('desktop'));
    }
  }

  private async _newVersionAlert(versionCode: string, downloadUrl: string, clearCache = false) {
    this._stopChecker();
    const alert = await this.alertController.create({
      header: 'Ha salido una nueva versión',
      subHeader: '',
      message: 'Actualiza a la nueva versión v' + versionCode + ' para disfrutar una mejor experiencia.',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this._startChecker();
          }
        }, {
          text: 'Actualizar',
          handler: () => {
            location.replace(location.href + (location.href.indexOf('?') >= 0 ? '&' : '?') + `versionDummy=${(new Date()).getTime()}`);
          }
        }
      ]
    });
    await alert.present();
  }

  private async _updateRequiredAlert(versionCode: string, downloadUrl: string, clearCache = false) {
    this._stopChecker();
    const alert = await this.alertController.create({
      header: 'Debes actualizar la aplicación',
      subHeader: '',
      message: 'Para seguir disfrutando del servicio debes actualizar a la versión v' + versionCode,
      backdropDismiss: false,
      keyboardClose: false,
      buttons: [{
        text: 'Actualizar',
        handler: () => {
          location.replace(location.href + (location.href.indexOf('?') >= 0 ? '&' : '?') + `versionDummy=${(new Date()).getTime()}`);
          return false;
        }
      }
      ]
    });

    await alert.present();
  }

  private async _updateAvailableSwAlert(clearCache = false) {
    this._stopChecker();
    const alert = await this.alertController.create({
      header: 'Ha salido una nueva versión',
      subHeader: '',
      message: 'Actualiza la aplicación para disfrutar una mejor experiencia.',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this._startChecker();
          }
        }, {
          text: 'Actualizar',
          handler: () => {
            location.replace(location.href + (location.href.indexOf('?') >= 0 ? '&' : '?') + `versionDummy=${(new Date()).getTime()}`);
          }
        }
      ]
    });
    await alert.present();
  }
}
