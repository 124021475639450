import { Injectable, Query } from "@angular/core";
import { AuthService } from "../core/auth.service";
import { Observable, BehaviorSubject } from "rxjs";
import {
  AppRequestQuery,
  AppPaginationHandlerDef,
  AppUserInformationDef,
  AppRsDinamycFormDef,
  AppNotificationDef,
  AppNotificationCountDef,
  AppDownloadRegistryResponse,
} from "src/app/api_interfaces/core";
import {
  AppOrganizationDef,
  AppOrganizationEntailmentDef,
  AppOrganizationOverviewDatasetResultDef,
  AppOrganizationOverviewDef,
} from "src/app/api_interfaces/organizations";
import { AppOrganizationProductDef } from "src/app/api_interfaces/organization_products";
import {
  AppOrganizationAsociationUserDef,
  AppOrganizationUserDef,
  AppOrganizationUserEntityEntailmentDef,
  AppOrganizationUserInvitationDef,
  AppOrganizationUserRegistrationDef,
} from "src/app/api_interfaces/organization_users";
import {
  AppOrganizationTicketDef,
  AppOrganizationTicketStatusDef,
  AppOrganizationTicketCommentDef,
  AppOrganizationTicketReportDef,
  AppOrganizationTicketReportTypeDef,
  AppOrganizationTicketReviewsDef,
} from "src/app/api_interfaces/organization_tickets";
import { AppOrganizationEquipmentDef } from "src/app/api_interfaces/organization_equipments";
import { AppOrganizationClientDef } from "src/app/api_interfaces/organization_clients";
import { AppOrganizationDepartmentDef } from "src/app/api_interfaces/organization_departments";
import { AppOrganizationRoleDef } from "src/app/api_interfaces/organization_roles";
import {
  AppOrganizationProcessDef,
  AppOrganizationProcessStageStatusDef,
} from "src/app/api_interfaces/organization_processes";
import { AppOrganizationFileDef } from "src/app/api_interfaces/organization_files";
import { AppOrganizationBranchOfficeDef } from "src/app/api_interfaces/organization_branch_office";

const currentOrganizationEntailment$: BehaviorSubject<AppOrganizationEntailmentDef> =
  new BehaviorSubject<AppOrganizationEntailmentDef>({
    organization: { id: 0 },
  } as AppOrganizationEntailmentDef);

@Injectable({
  providedIn: "root",
})
export class ClientOrganizationOverviews {
  constructor(private auth: AuthService) { }

  get(): Observable<AppOrganizationOverviewDef[]> {
    return this.auth._post("/organization_overviews/get.json", {
      organization_id: currentOrganizationEntailment$.value.organization.id,
    });
  }

  getDataSetData(data: {
    dataset_id: number;
    queries: {
      query: AppRequestQuery;
      id: string;
    }[];
  }): Observable<AppOrganizationOverviewDatasetResultDef[]> {
    data.queries = JSON.stringify(data.queries) as any;
    return this.auth._post(
      "/organization_overviews/get_dataset_data.json",
      data
    );
  }
}

@Injectable({
  providedIn: "root",
})
export class ClientOrganizationUtils {
  constructor(private auth: AuthService) { }
  /**
   *
   */
  public inputs = {
    suggestions: {
      get: (data: {
        query:
        | {
          item_type: string;
          context: string;
          hint: string;
        }
        | string;
      }): Observable<string[]> => {
        (data as any).organization_id =
          currentOrganizationEntailment$.value.organization.id;
        data.query = JSON.stringify(data.query);
        return this.auth._post(
          "/organization_input_suggestions/get.json",
          data
        );
      },
    },
  };
}

@Injectable({
  providedIn: "root",
})
export class ClientOrganizationFiles {
  public tags = {
    /**
     *  Soporta las columnas tag, organization, createDate en unix milisegundos
     */
    get: (
      data: { query: AppRequestQuery } | any
    ): Observable<AppPaginationHandlerDef<string>> => {
      data.query.conds.organization = {
        eq: currentOrganizationEntailment$.value.organization.id,
      };
      data.query.conds = { and: data.query.conds };
      data.query = JSON.stringify(data.query);
      return this.auth._post("/organization_files/tags_get.json", data);
    },
  };

  constructor(private auth: AuthService) { }
  /**
   *
   */
  upload(data: {
    files: { name: string; item_type_name?: string; store: boolean }[] | string;
  }): Observable<AppOrganizationFileDef[]> {
    data.files = JSON.stringify(data.files);
    (data as any).organization_id =
      currentOrganizationEntailment$.value.organization.id;
    return this.auth._post("/organization_files/upload.json", data);
  }

  link(data: {
    files: { id: string; item_type_name: string }[] | string;
  }): Observable<AppOrganizationFileDef[]> {
    data.files = JSON.stringify(data.files);
    (data as any).organization_id =
      currentOrganizationEntailment$.value.organization.id;
    return this.auth._post("/organization_files/link.json", data);
  }

  /*get(data: {
    data: { id: string | string[] } | string
  }): Observable<AppOrganizationFileDef[]> {
    data.data = JSON.stringify(data.data);
    return this.auth._post('/organization_files/get.json', data);
  }*/

  get(
    data: { query: AppRequestQuery } | any
  ): Observable<AppPaginationHandlerDef<AppOrganizationFileDef>> {
    data.query.conds.organization = {
      eq: currentOrganizationEntailment$.value.organization.id,
    };
    data.query.conds = { and: data.query.conds };
    data.query = JSON.stringify(data.query);
    return this.auth._post("/organization_files/get.json", data);
  }

  edit(data: {
    file_id: string;
    data:
    | {
      tags: string[];
    }
    | string;
  }): Observable<AppOrganizationFileDef> {
    data.data = JSON.stringify(data.data);
    (data as any).organization_id = (data as any).organization_id
      ? (data as any).organization_id
      : currentOrganizationEntailment$.value.organization.id;
    return this.auth._post("/organization_files/edit.json", data);
  }
}

@Injectable({
  providedIn: "root",
})
export class ClientOrganizationProcesses {
  constructor(private auth: AuthService) { }
  /**
   *
   */
  get(
    data: { query: AppRequestQuery } | any
  ): Observable<AppPaginationHandlerDef<AppOrganizationProcessDef>> {
    data.query.conds.organization = {
      eq: currentOrganizationEntailment$.value.organization.id,
    };
    data.query.conds = { and: data.query.conds };
    data.query = JSON.stringify(data.query);
    return this.auth._post("/organization_processes/get.json", data);
  }
  /**
   *
   */
  create(data: {
    stages:
    | {
      name: string;
      description: string;
      assignee: number;
      skippable: boolean;
      duration: number;
      status: number;
      ticket: {
        status: number;
        evaluable: boolean;
      };
      entityTo: number;
    }[]
    | string;
    forms:
    | {
      creation: {
        ticket: number;
      };
    }
    | string;
    name: string;
    description: string;
    report_types: number[] | string;
  }): Observable<AppOrganizationProcessDef> {
    data.stages = JSON.stringify(data.stages);
    data.forms = JSON.stringify(data.forms);
    data.report_types = JSON.stringify(data.report_types);
    (data as any).organization_id = (data as any).organization_id
      ? (data as any).organization_id
      : currentOrganizationEntailment$.value.organization.id;
    return this.auth._post("/organization_processes/create.json", data);
  }
  /**
   *
   */
  edit(data: {
    process_id: number;
    data:
    | {
      stages: {
        id?: number;
        name: string;
        description: string;
        assignee: number;
        skippable: boolean;
        duration: number;
        status: number;
        ticket: {
          status: number;
          evaluable: boolean;
        };
        entityTo: number;
      }[];
      name: string;
      description: string;
      forms: {
        creation: {
          ticket: number;
        };
      };
      report_types: number[];
    }
    | string;
  }): Observable<AppOrganizationProcessDef> {
    data.data = JSON.stringify(data.data);
    (data as any).organization_id = (data as any).organization_id
      ? (data as any).organization_id
      : currentOrganizationEntailment$.value.organization.id;
    return this.auth._post("/organization_processes/edit.json", data);
  }
  /**
   *
   */
  delete(data: { process_id: number }): Observable<AppOrganizationProcessDef> {
    return this.auth._post("/organization_processes/delete.json", data);
  }
}

@Injectable({
  providedIn: "root",
})
export class ClientOrganizationBranches {
  constructor(private auth: AuthService) { }
  /**
   *
   */
  get(
    data: { query: AppRequestQuery } | any
  ): Observable<AppPaginationHandlerDef<AppOrganizationBranchOfficeDef>> {
    data.query.conds.organization = {
      eq: currentOrganizationEntailment$.value.organization.id,
    };
    data.query.conds = { and: data.query.conds };
    data.query = JSON.stringify(data.query);
    return this.auth._post("/organization_entities/get.json", data);
  }
  /**
   *
   */
  create(data: {
    organization_id?: number; // No es necesario
    parent_entity?: number; // Opcional
    entity_type: number; // Enviroments entityTypeDef
    data: any; // Form data attrs
  }): Observable<AppOrganizationBranchOfficeDef> {
    data.data = JSON.stringify(data.data);
    // Auto - Insert organization id
    data.organization_id = data.organization_id
      ? data.organization_id
      : currentOrganizationEntailment$.value.organization.id;
    return this.auth._post("/organization_entities/create.json", data);
  }
  /**
   *
   */
  edit(data: {
    entity_id: number;
    data: { attrs?: any; parentEntity?: number } | string;
  }): Observable<AppOrganizationBranchOfficeDef> {
    data.data = JSON.stringify(data.data);
    return this.auth._post("/organization_entities/edit.json", data);
  }
  /**
   *
   */
  delete(data: {
    entity_id: number;
  }): Observable<AppOrganizationBranchOfficeDef> {
    return this.auth._post("/organization_entities/delete.json", data);
  }
}

@Injectable({
  providedIn: "root",
})
export class ClientOrganizatioEntities {
  constructor(private auth: AuthService) { }
  /**
   *
   */
  get(
    data: { query: AppRequestQuery } | any
  ): Observable<AppPaginationHandlerDef<AppOrganizationDepartmentDef | AppOrganizationClientDef | AppOrganizationBranchOfficeDef>> {
    data.query.conds.organization = {
      eq: currentOrganizationEntailment$.value.organization.id,
    };
    data.query.conds = { and: data.query.conds };
    data.query = JSON.stringify(data.query);
    return this.auth._post("/organization_entities/get.json", data);
  }
  /**
   *
   */
  create(data: {
    organization_id?: number; // No es necesario
    data: any; // Form data attrs
  }): Observable<AppOrganizationDepartmentDef | AppOrganizationClientDef | AppOrganizationBranchOfficeDef> {
    data.data = JSON.stringify(data.data);
    // Auto - Insert organization id
    data.organization_id = data.organization_id
      ? data.organization_id
      : currentOrganizationEntailment$.value.organization.id;
    return this.auth._post("/organization_entities/create.json", data);
  }
  /**
   *
   */
  edit(data: {
    department_id: number;
    data: {};
  }): Observable<AppOrganizationDepartmentDef | AppOrganizationClientDef | AppOrganizationBranchOfficeDef> {
    data.data = JSON.stringify(data.data);
    return this.auth._post("/organization_entities/edit.json", data);
  }
  /**
   *
   */
  delete(data: {
    department_id: number;
  }): Observable<AppOrganizationDepartmentDef | AppOrganizationClientDef | AppOrganizationBranchOfficeDef> {
    return this.auth._post("/organization_entities/delete.json", data);
  }
}

@Injectable({
  providedIn: "root",
})
export class ClientOrganizationDepartments {
  constructor(private auth: AuthService) { }
  /**
   *
   */
  get(
    data: { query: AppRequestQuery } | any
  ): Observable<AppPaginationHandlerDef<AppOrganizationDepartmentDef>> {
    data.query.conds.organization = {
      eq: currentOrganizationEntailment$.value.organization.id,
    };
    data.query.conds = { and: data.query.conds };
    data.query = JSON.stringify(data.query);
    return this.auth._post("/organization_departments/get.json", data);
  }
  /**
   *
   */
  create(data: {
    organization_id?: number; // No es necesario
    data: any; // Form data attrs
  }): Observable<AppOrganizationDepartmentDef> {
    data.data = JSON.stringify(data.data);
    // Auto - Insert organization id
    data.organization_id = data.organization_id
      ? data.organization_id
      : currentOrganizationEntailment$.value.organization.id;
    return this.auth._post("/organization_departments/create.json", data);
  }
  /**
   *
   */
  edit(data: {
    department_id: number;
    data: {};
  }): Observable<AppOrganizationDepartmentDef> {
    data.data = JSON.stringify(data.data);
    return this.auth._post("/organization_departments/edit.json", data);
  }
  /**
   *
   */
  delete(data: {
    department_id: number;
  }): Observable<AppOrganizationDepartmentDef> {
    return this.auth._post("/organization_departments/delete.json", data);
  }
}


@Injectable({
  providedIn: "root",
})
export class ClientOrganizationClients {
  constructor(private auth: AuthService) { }
  /**
   *
   */
  get(
    data: { query: AppRequestQuery } | any
  ): Observable<AppPaginationHandlerDef<AppOrganizationClientDef>> {
    data.query.conds.organization = {
      eq: currentOrganizationEntailment$.value.organization.id,
    };
    data.query.conds = { and: data.query.conds };
    data.query = JSON.stringify(data.query);
    return this.auth._post("/organization_clients/get.json", data);
  }
  /**
   *
   */
  create(data: {
    organization_id?: number; // No es necesario
    data: any; // Form data attrs
  }): Observable<AppOrganizationClientDef> {
    data.data = JSON.stringify(data.data);
    // Auto - Insert organization id
    data.organization_id = data.organization_id
      ? data.organization_id
      : currentOrganizationEntailment$.value.organization.id;
    return this.auth._post("/organization_clients/create.json", data);
  }
  /**
   *
   */
  edit(data: {
    client_id: number;
    data: {};
  }): Observable<AppOrganizationClientDef> {
    data.data = JSON.stringify(data.data);
    return this.auth._post("/organization_clients/edit.json", data);
  }
  /**
   *
   */
  delete(data: { client_id: number }): Observable<AppOrganizationClientDef> {
    return this.auth._post("/organization_clients/delete.json", data);
  }
  /**
   *
   */
  downloadRegistries(
    data: { query: AppRequestQuery } | any
  ): Observable<AppDownloadRegistryResponse> {
    /* data.query.conds[0].and[0].organization = {
      eq: currentOrganizationEntailment$.value.organization.id,
    };
    data.query = JSON.stringify(data.query);
    data.query_version = 2; */
    data.query.conds.organization = {
      eq: currentOrganizationEntailment$.value.organization.id,
    };
    data.query.conds = { and: data.query.conds };
    data.query = JSON.stringify(data.query);
    return this.auth._post("/organization_clients/download_registry.json", data);
  }
}

@Injectable({
  providedIn: "root",
})
export class ClientOrganizationEquipments {
  constructor(private auth: AuthService) { }
  /**
   *
   */
  get(
    data: { query: AppRequestQuery } | any
  ): Observable<AppPaginationHandlerDef<AppOrganizationEquipmentDef>> {
    data.query.conds.organization = {
      eq: currentOrganizationEntailment$.value.organization.id,
    };
    data.query.conds = { and: data.query.conds };
    data.query = JSON.stringify(data.query);
    return this.auth._post("/organization_equipments/get.json", data);
  }
  /**
   *
   */
  create(data: {
    organization_id?: number; // No es necesario
    data: any; // Form data attrs
    product_id: number;
    entity_id: number;
  }): Observable<AppOrganizationEquipmentDef> {
    data.data = JSON.stringify(data.data);
    // Auto - Insert organization id
    data.organization_id = data.organization_id
      ? data.organization_id
      : currentOrganizationEntailment$.value.organization.id;
    return this.auth._post("/organization_equipments/create.json", data);
  }
  /**
   *
   */
  edit(data: {
    equipment_id: number;
    data:
    | {
      attrs?: any;
      product?: { id: number; comments: string };
      entity?: { id: number; comments: string };
    }
    | string;
  }): Observable<AppOrganizationEquipmentDef> {
    data.data = JSON.stringify(data.data);
    return this.auth._post("/organization_equipments/edit.json", data);
  }
  /**
   *
   */
  delete(data: {
    equipment_id: number;
  }): Observable<AppOrganizationEquipmentDef> {
    return this.auth._post("/organization_equipments/delete.json", data);
  }
  /**
   *
   */
  downloadRegistries(
    data: { query: AppRequestQuery } | any
  ): Observable<AppDownloadRegistryResponse> {
    /* data.query.conds[0].and[0].organization = {
      eq: currentOrganizationEntailment$.value.organization.id,
    };
    data.query = JSON.stringify(data.query);
    data.query_version = 2; */
    data.query.conds.organization = {
      eq: currentOrganizationEntailment$.value.organization.id,
    };
    data.query.conds = { and: data.query.conds };
    data.query = JSON.stringify(data.query);
    return this.auth._post("/organization_equipments/download_registry.json", data);
  }
}

@Injectable({
  providedIn: "root",
})
export class ClientOrganizationTickets {
  constructor(private auth: AuthService) { }

  /**
   * EVALUATIONS
   */
  public evaluations = {
    create: (data: {
      ticket_id: number;
      rating: number;
      comments: string;
    }): Observable<AppOrganizationTicketDef> => {
      (data as any).organization_id =
        currentOrganizationEntailment$.value.organization.id;
      return this.auth._post(
        "/organization_tickets/create_evaluation.json",
        data
      );
    },
  };

  /**
   * REPORTS
   */
  public reports = {
    create: (data: {
      ticket_id: number;
      report_type: number;
      data: any;
    }): Observable<AppOrganizationTicketReportDef> => {
      (data as any).data = JSON.stringify((data as any).data);
      (data as any).organization_id =
        currentOrganizationEntailment$.value.organization.id;
      return this.auth._post("/organization_tickets/create_report.json", data);
    },
    get: (data: {
      ticket_id?: number;
      report_id?: number;
    }): Observable<AppOrganizationTicketReportDef[]> => {
      (data as any).organization_id =
        currentOrganizationEntailment$.value.organization.id;
      return this.auth._post("/organization_tickets/get_report.json", data);
    },
    edit: (data: {
      report_id: number;
      data:
      | {
        attrs?: any;
      }
      | string;
    }): Observable<AppOrganizationTicketReportDef> => {
      (data as any).data = JSON.stringify((data as any).data);
      (data as any).organization_id =
        currentOrganizationEntailment$.value.organization.id;
      return this.auth._post("/organization_tickets/edit_report.json", data);
    },
    delete: (data: {
      report_id: number;
    }): Observable<AppOrganizationTicketReportDef> => {
      (data as any).organization_id =
        currentOrganizationEntailment$.value.organization.id;
      return this.auth._post("/organization_tickets/delete_report.json", data);
    },
  };

  /**
   * REPORT TYPES
   */
  public reportTypes = {
    create: (data: {
      name: string;
      description: string;
      form: string;
    }): Observable<AppOrganizationTicketReportTypeDef> => {
      (data as any).organization_id =
        currentOrganizationEntailment$.value.organization.id;
      return this.auth._post(
        "/organization_tickets/create_report_type.json",
        data
      );
    },
    get: (
      data:
        | {
          query: AppRequestQuery;
        }
        | any
    ): Observable<
      AppPaginationHandlerDef<AppOrganizationTicketReportTypeDef>
    > => {
      data.query.conds.organization = {
        eq: currentOrganizationEntailment$.value.organization.id,
      };
      data.query.conds = { and: data.query.conds };
      data.query = JSON.stringify(data.query);
      return this.auth._post(
        "/organization_tickets/get_report_type.json",
        data
      );
    },
    edit: (data: {
      report_id: number;
      data:
      | {
        form?: number;
        name?: string;
        description?: string;
      }
      | string;
    }): Observable<AppOrganizationTicketReportTypeDef> => {
      (data as any).data = JSON.stringify((data as any).data);
      (data as any).organization_id =
        currentOrganizationEntailment$.value.organization.id;
      return this.auth._post(
        "/organization_tickets/edit_report_type.json",
        data
      );
    },
    delete: (data: {
      report_id: number;
    }): Observable<AppOrganizationTicketReportTypeDef> => {
      (data as any).organization_id =
        currentOrganizationEntailment$.value.organization.id;
      return this.auth._post(
        "/organization_tickets/delete_report_type.json",
        data
      );
    },
  };

  /**
   * REVIWES
   */
  public reviews = {
    create: (data: {
      report_id: number;
      rating: number;
      comments: string;
    }): Observable<AppOrganizationTicketReviewsDef> => {
      (data as any).organization_id =
        currentOrganizationEntailment$.value.organization.id;
      return this.auth._post(
        "/organization_tickets/create_report_review.json",
        data
      );
    },
    get: (
      data:
        | {
          query: AppRequestQuery;
        }
        | any
    ): Observable<AppPaginationHandlerDef<AppOrganizationTicketReviewsDef>> => {
      data.query.conds.organization = {
        eq: currentOrganizationEntailment$.value.organization.id,
      };
      data.query.conds = { and: data.query.conds };
      data.query = JSON.stringify(data.query);
      return this.auth._post(
        "/organization_tickets/get_report_review.json",
        data
      );
    },
  };

  /**
   * COMMENTS
   */
  public comments = {
    create: (data: {
      ticket_id: number;
      comment: string;
      privacy: string; // ticketCommentsPrivacyDef
    }): Observable<AppOrganizationTicketCommentDef> => {
      (data as any).organization_id =
        currentOrganizationEntailment$.value.organization.id;
      return this.auth._post("/organization_tickets/create_comment.json", data);
    },
    delete: (data: {
      comment_id: number;
    }): Observable<AppOrganizationTicketCommentDef> => {
      (data as any).organization_id =
        currentOrganizationEntailment$.value.organization.id;
      return this.auth._post("/organization_tickets/delete_comment.json", data);
    },
    get: (data: {
      ticket_id: number;
    }): Observable<AppOrganizationTicketCommentDef[]> => {
      (data as any).organization_id =
        currentOrganizationEntailment$.value.organization.id;
      return this.auth._post("/organization_tickets/get_comment.json", data);
    },
    edit: (data: {
      comment_id: number;
      data: {
        comment?: string;
        privacy?: string; // ticketCommentsPrivacyDef
      };
    }): Observable<AppOrganizationTicketCommentDef> => {
      (data as any).data = JSON.stringify((data as any).data);
      (data as any).organization_id =
        currentOrganizationEntailment$.value.organization.id;
      return this.auth._post("/organization_tickets/edit_comment.json", data);
    },
  };

  /**
   *
   */
  get(
    data: { query: AppRequestQuery } | any
    // query_version: number = 0
  ): Observable<AppPaginationHandlerDef<AppOrganizationTicketDef>> {
    data.query.conds.organization = {
      eq: currentOrganizationEntailment$.value.organization.id,
    };
    data.query.conds = { and: data.query.conds };
    data.query = JSON.stringify(data.query);

    return this.auth._post("/organization_tickets/get.json", data);
  }
  /**
   *
   */
  getV2(
    data: { query: AppRequestQuery } | any
  ): Observable<AppPaginationHandlerDef<AppOrganizationTicketDef>> {
    data.query.conds[0].and.push({ organization: { eq: currentOrganizationEntailment$.value.organization.id } });
    data.query = JSON.stringify(data.query);
    data.query_version = 2;

    return this.auth._post("/organization_tickets/get.json", data);
  }
  /**
   *
   */
  downloadRegistries(
    data: { query: AppRequestQuery } | any
  ): Observable<AppDownloadRegistryResponse> {
    /* data.query.conds[0].and[0].organization = {
      eq: currentOrganizationEntailment$.value.organization.id,
    }; */
    data.query.conds[0].and.unshift({
      organization: { eq: currentOrganizationEntailment$.value.organization.id }
    });
    data.query = JSON.stringify(data.query);
    data.query_version = 2;
    return this.auth._post("/organization_tickets/download_registry.json", data);
  }
  /**
   *
   */
  create(data: {
    entity_to: number;
    entity_from: number;
    owner: number;
    assignee: number;
    subject: string;
    details: string;
    watchers?: number[]; // array con los id de lso watchers
    process_id: number;
    data: any; // Form data attrs
    organization_id?: number; // No es necesario,
    attachments?: string[] | string;
  }): Observable<AppOrganizationTicketDef> {
    data.data = JSON.stringify(data.data);
    // Auto - Insert organization id
    data.organization_id = data.organization_id
      ? data.organization_id
      : currentOrganizationEntailment$.value.organization.id;
    data.attachments = JSON.stringify(data.attachments);
    return this.auth._post("/organization_tickets/create.json", data);
  }

  /**
   *
   */
  edit(data: {
    ticket_id: number;
    data:
    | {
      stage?: { id: number; comments: string };
      status?: { id: number; comments: string };
      stage_status?: { id: number; data: any; comments: string };
      attrs?: any; // Attrs form
      assignee?: { id: number; comments: string };
      owner?: { id: number; comments: string };
      entityTo?: { id: number; comments: string };
      entityFrom?: { id: number; comments: string };
      watchers?: number[];
      subject?: string;
      details?: string;
      attachments?: string[] | string;
      process?: {
        // se puede dejar el stage en cero ( 0 ) si no se quiere enviar un stage especifico
        id: number;
        attrs: any;
        stage: number;
      };
    }
    | string;
  }): Observable<AppOrganizationTicketDef> {
    data.data = JSON.stringify(data.data);
    return this.auth._post("/organization_tickets/edit.json", data);
  }
  /**
   *
   */
  delete(data: { ticket_id: number }): Observable<AppOrganizationTicketDef> {
    return this.auth._post("/organization_tickets/delete.json", data);
  }
  /**
   *
   */
  availableStatus(): Observable<AppOrganizationTicketStatusDef[]> {
    return this.auth._post("/organization_tickets/available_status.json", {
      organization_id: currentOrganizationEntailment$.value.organization.id,
    });
  }
  /**
   *
   */
  availableStageStatus(): Observable<AppOrganizationProcessStageStatusDef[]> {
    return this.auth._post(
      "/organization_tickets/available_stage_status.json",
      {
        organization_id: currentOrganizationEntailment$.value.organization.id,
      }
    );
  }
}

@Injectable({
  providedIn: "root",
})
export class ClientOrganizationRoles {
  constructor(private auth: AuthService) { }
  /**
   *
   */
  get(
    data: { query: AppRequestQuery } | any
  ): Observable<AppPaginationHandlerDef<AppOrganizationRoleDef>> {
    data.query.conds.organization = {
      eq: currentOrganizationEntailment$.value.organization.id,
    };
    data.query.conds = { and: data.query.conds };
    data.query = JSON.stringify(data.query);
    return this.auth._post("/organization_roles/get.json", data);
  }
  /**
   *
   */
  create(data: {
    name: string;
    description: string;
    perms: {
      scopeType: number;
      itemType: number;
      actionLevel: number;
      subItems: {
        subItemId: string;
        actionLevel: number;
      }[];
    }[];
  }): Observable<AppOrganizationRoleDef> {
    (data as any).organization_id =
      currentOrganizationEntailment$.value.organization.id;
    data.perms = JSON.stringify(data.perms) as any;
    return this.auth._post("/organization_roles/create.json", data);
  }
  /**
   *
   */
  edit(data: {
    role_id: number;
    name: string;
    description: string;
    perms: {
      id: number;
      scopeType: number;
      itemType: number;
      actionLevel: number;
      subItems: {
        subItemId: string;
        actionLevel: number;
      }[];
    }[];
  }): Observable<AppOrganizationRoleDef> {
    return this.auth._post("/organization_roles/edit.json", data);
  }
  /**
   *
   */
  delete(data: { role_id: number }): Observable<AppOrganizationRoleDef> {
    return this.auth._post("/organization_roles/delete.json", data);
  }
}

@Injectable({
  providedIn: "root",
})
export class ClientOrganizationUsers {
  constructor(private auth: AuthService) { }

  /**
   * REGISTRATIONS
   */
  public registrations = {
    get: (
      data: { notEntailments?: 0 | 1; query: AppRequestQuery } | any
    ): Observable<
      AppPaginationHandlerDef<AppOrganizationUserRegistrationDef>
    > => {
      data.query.conds.organization = {
        eq: currentOrganizationEntailment$.value.organization.id,
      };
      data.query.conds = { and: data.query.conds };
      data.query = JSON.stringify(data.query);
      return this.auth._post("/organization_users/get_registration.json", data);
    },
  };

  /**
   * ENTAILMENTS
   */
  public entailments = {
    get: (
      data: { query: AppRequestQuery } | any
    ): Observable<
      AppPaginationHandlerDef<AppOrganizationUserEntityEntailmentDef>
    > => {
      data.query.conds.organization = {
        eq: currentOrganizationEntailment$.value.organization.id,
      };
      data.query.conds = { and: data.query.conds };
      data.query = JSON.stringify(data.query);
      return this.auth._post("/organization_users/get_entailment.json", data);
    },
    create: (data: {
      user_id: number;
      entity_id: number;
      role_id: number;
    }): Observable<AppOrganizationUserEntityEntailmentDef> => {
      (data as any).organization_id =
        currentOrganizationEntailment$.value.organization.id;
      return this.auth._post(
        "/organization_users/create_entailment.json",
        data
      );
    },
    edit: (data: {
      entailment_id: number;
      role_id: number;
    }): Observable<AppOrganizationUserEntityEntailmentDef> => {
      (data as any).organization_id =
        currentOrganizationEntailment$.value.organization.id;
      return this.auth._post("/organization_users/edit_entailment.json", data);
    },
    delete: (data: {
      entailment_id: number;
    }): Observable<AppOrganizationUserEntityEntailmentDef> => {
      (data as any).organization_id =
        currentOrganizationEntailment$.value.organization.id;
      return this.auth._post(
        "/organization_users/delete_entailment.json",
        data
      );
    },
  };

  /**
   * IVITATIONS
   */
  public invitations = {
    get: (
      data: { query: AppRequestQuery } | any
    ): Observable<
      AppPaginationHandlerDef<AppOrganizationUserInvitationDef>
    > => {
      data.query.conds.organization = {
        eq: currentOrganizationEntailment$.value.organization.id,
      };
      data.query.conds = { and: data.query.conds };
      data.query = JSON.stringify(data.query);
      return this.auth._post("/organization_users/get_invitation.json", data);
    },
    create: (data: {
      data: {
        user_email: string;
        entity_id: number;
        role_id: number;
      }[];
    }): Observable<AppOrganizationUserInvitationDef[]> => {
      (data as any).organization_id =
        currentOrganizationEntailment$.value.organization.id;
      (data as any).data = JSON.stringify(data.data);
      return this.auth._post(
        "/organization_users/create_invitation.json",
        data
      );
    },
    delete: (data: {
      invitation_id: number;
    }): Observable<AppOrganizationUserInvitationDef> => {
      (data as any).organization_id =
        currentOrganizationEntailment$.value.organization.id;
      return this.auth._post(
        "/organization_users/delete_invitation.json",
        data
      );
    },
    send_email: (data: {
      invitation_id: number;
    }): Observable<AppOrganizationUserInvitationDef> => {
      (data as any).organization_id =
        currentOrganizationEntailment$.value.organization.id;
      return this.auth._post(
        "/organization_users/send_invitation_email.json",
        data
      );
    },
  };

  /**
   *
   */
  get(
    data: { query: AppRequestQuery } | any
  ): Observable<AppPaginationHandlerDef<AppOrganizationAsociationUserDef>> {
    data.query.conds.organization = {
      eq: currentOrganizationEntailment$.value.organization.id,
    };
    data.query.conds = { and: data.query.conds };
    data.query = JSON.stringify(data.query);
    return this.auth._post("/organization_users/get.json", data);
  }
  /**
   *
   */
  /*create(data: {

  }): Observable<AppOrganizationUserDef> {
    return this.auth._post('/organization_users/create.json', data);
  }*/
  /**
   *
   */
  /*edit(data: {
    user_id: number
    data: {}
  }): Observable<AppOrganizationUserDef> {
    data.data = JSON.stringify(data.data);
    return this.auth._post('/organization_users/edit.json', data);
  }*/
  /**
   *
   */
  /*delete(data: { user_id: number }): Observable<AppOrganizationUserDef> {
    return this.auth._post('/organization_users/delete.json', data);
  }*/
}

@Injectable({
  providedIn: "root",
})
export class ClientOrganizationForms {
  constructor(private auth: AuthService) { }
  /**
   *
   */
  get(
    data: { query: AppRequestQuery } | any
  ): Observable<AppPaginationHandlerDef<AppRsDinamycFormDef>> {
    data.query.conds.organization = {
      eq: currentOrganizationEntailment$.value.organization.id,
    };
    data.query.conds = { and: data.query.conds };
    data.query = JSON.stringify(data.query);
    return this.auth._post("/organization_forms/get.json", data);
  }
  /**
   *
   */
  create(data: { fields: any; name: string }): Observable<AppRsDinamycFormDef> {
    data.fields = JSON.stringify(data.fields);
    (data as any).organization_id = (data as any).organization_id
      ? (data as any).organization_id
      : currentOrganizationEntailment$.value.organization.id;
    return this.auth._post("/organization_forms/create.json", data);
  }
  /**
   *
   */
  edit(data: {
    form_id: number;
    data:
    | {
      name?: string;
      fields?: any;
    }
    | string;
  }): Observable<AppRsDinamycFormDef> {
    data.data = JSON.stringify(data.data);
    return this.auth._post("/organization_forms/edit.json", data);
  }
  /**
   *
   */
  delete(data: { form_id: number }): Observable<AppRsDinamycFormDef> {
    return this.auth._post("/organization_forms/delete.json", data);
  }
}

@Injectable({
  providedIn: "root",
})
export class ClientOrganizationReports {
  constructor(private auth: AuthService) { }
  /**
   *
   */
  getV2(
    data: { query: AppRequestQuery } | any
  ): Observable<AppPaginationHandlerDef<AppOrganizationTicketReportDef>> {
    data.query.conds[0].and[0].organization = {
      eq: currentOrganizationEntailment$.value.organization.id,
    };
    data.query = JSON.stringify(data.query);
    data.query_version = 2;
    return this.auth._post("/organization_tickets/get_report.json", data);
  }
}

@Injectable({
  providedIn: "root",
})
export class ClientOrganizationProducts {
  constructor(private auth: AuthService) { }
  /**
   *
   */
  get(
    data: { query: AppRequestQuery } | any
  ): Observable<AppPaginationHandlerDef<AppOrganizationProductDef>> {
    if (data?.query_version > 1) {
      data.query.conds.push({
        and: [
          {
            organization: {
              eq: currentOrganizationEntailment$.value.organization.id,
            },
          },
        ],
      });
    } else {
      data.query.conds.organization = {
        eq: currentOrganizationEntailment$.value.organization.id,
      };
      data.query.conds = { and: data.query.conds };
    }
    data.query = JSON.stringify(data.query);
    return this.auth._post("/organization_products/get.json", data);
  }
  /**
   *
   */
  create(data: {
    organization_id?: number; // No es necesario
    data: any; // Form data attrs
  }): Observable<AppOrganizationProductDef> {
    data.data = JSON.stringify(data.data);
    // Auto - Insert organization id
    data.organization_id = data.organization_id
      ? data.organization_id
      : currentOrganizationEntailment$.value.organization.id;
    return this.auth._post("/organization_products/create.json", data);
  }
  /**
   *
   */
  edit(data: {
    product_id: number;
    data: {};
  }): Observable<AppOrganizationProductDef> {
    data.data = JSON.stringify(data.data);
    return this.auth._post("/organization_products/edit.json", data);
  }
  /**
   *
   */
  delete(data: { product_id: number }): Observable<AppOrganizationProductDef> {
    return this.auth._post("/organization_products/delete.json", data);
  }
}

@Injectable({
  providedIn: "root",
})
export class ClientOrganizations {
  constructor(
    private auth: AuthService,
    public entities: ClientOrganizatioEntities,
    public deparments: ClientOrganizationDepartments,
    public clients: ClientOrganizationClients,
    public branches: ClientOrganizationBranches,
    public equipments: ClientOrganizationEquipments,
    public tickets: ClientOrganizationTickets,
    public roles: ClientOrganizationRoles,
    public users: ClientOrganizationUsers,
    public products: ClientOrganizationProducts,
    public reports: ClientOrganizationReports,
    public processes: ClientOrganizationProcesses,
    public files: ClientOrganizationFiles,
    public utils: ClientOrganizationUtils,
    public forms: ClientOrganizationForms,
    public overview: ClientOrganizationOverviews
  ) {
    this.auth._getUserInformation().subscribe({
      next: (userInfo: AppUserInformationDef) => {
        if (userInfo && userInfo.user && userInfo.user.id > 0) {
          if (userInfo.organization_entailments.length > 0) {
            this.setCurrentEntailment(userInfo.organization_entailments[0]);
          } else {
            // Redirecto to non organization screen
          }
        } else {
        }
      },
    });
  }

  setCurrentOrganization(organization: AppOrganizationDef) {
    const currentEntailment = JSON.parse(
      JSON.stringify(currentOrganizationEntailment$.value)
    );
    currentEntailment.organization = organization;
    currentOrganizationEntailment$.next(currentEntailment);
  }

  setCurrentEntailment(entailment: AppOrganizationEntailmentDef) {
    currentOrganizationEntailment$.next(entailment);
  }

  getCurrentEntailment(): Observable<AppOrganizationEntailmentDef> {
    return currentOrganizationEntailment$.asObservable();
  }

  /**
   *
   */
  get(): Observable<AppOrganizationDef[]> {
    return this.auth._post("/organization_profile/get.json", {
      organization_id: currentOrganizationEntailment$.value.organization.id,
    });
  }

  /**
   *
   */
  getPublicProfile(data: { token: string }): Observable<AppOrganizationDef> {
    return this.auth._post(
      "/organization_profile/get_public_profile.json",
      data
    );
  }

  /**
   *
   */
  edit(data: {
    data:
    | {
      attrs?: any;
      storagePaths?: {
        organization: string;
        product: string;
        equipment: string;
        ticket: string;
        client: string;
      };
      status?: { id: number; comments: string };
    }
    | string;
  }): Observable<AppOrganizationDef> {
    data.data = JSON.stringify(data.data);
    (data as any).organization_id =
      currentOrganizationEntailment$.value.organization.id;
    return this.auth._post("/organization_profile/edit.json", data);
  }

  connectExternalService(data: {
    service_type: string;
    service_token?: string;
  }): Observable<AppOrganizationDef | string> {
    (data as any).organization_id =
      currentOrganizationEntailment$.value.organization.id;
    return this.auth._post("/organization_profile/connect_service.json", data);
  }

  disconnectExternalService(): Observable<AppOrganizationDef> {
    return this.auth._post("/organization_profile/disconnect_service.json", {
      organization_id: currentOrganizationEntailment$.value.organization.id,
    });
  }
}

@Injectable({
  providedIn: "root",
})
export class ClientNotifications {
  constructor(private auth: AuthService) { }

  get(
    data: { query: AppRequestQuery } | any
  ): Observable<AppNotificationDef[]> {
    data.query = JSON.stringify(data.query);
    return this.auth._post("/client_notifications/get.json", data);
  }

  markAsRead(data: { notification_ids: number[] }): Observable<AppNotificationDef[]> {
    data.notification_ids = JSON.stringify(data.notification_ids) as any;
    return this.auth._post("/client_notifications/markRead.json", data);
  }

  markAllAsRead(data: {}): Observable<AppNotificationDef[]> {
    return this.auth._post("/client_notifications/mark_all_read.json", data);
  }

  count(
    data: { query: AppRequestQuery } | any
  ): Observable<AppNotificationCountDef> {
    data.query = JSON.stringify(data.query);
    return this.auth._post("/client_notifications/count.json", data);
  }
}
@Injectable({
  providedIn: "root",
})
export class ClientSurvey {
  constructor(private auth: AuthService) { }

  post(data: {
    id: number;
    value: number;
    description: string;
  }): Observable<boolean> {
    return this.auth._post("/client_survey/create.json", data);
  }
}

@Injectable({
  providedIn: "root",
})
export class ClientAPIService {
  constructor(
    public organization: ClientOrganizations,
    public surveys: ClientSurvey,
    public notifications: ClientNotifications // public survey: ClientSurvey
  ) { }
}
