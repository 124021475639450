import { Injectable } from '@angular/core';
import { Observable, throwError, Subscriber, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { HttpsService } from './https.service';
import { map, catchError } from 'rxjs/operators';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { isApp, loggedOutgNavigationDefault, loggedIngNavigationDefault } from 'src/environments/environment';
import { StorageService } from '../../utils/storage.service';
import { AppUserInformationDef } from 'src/app/api_interfaces/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedOutUserInfo = { user: { id: 0 } };
  private authToken$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private userInformation$: BehaviorSubject<any> = new BehaviorSubject<any>(this.loggedOutUserInfo);
  private initialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(
    private storage: StorageService,
    private router: Router,
    private http: HttpsService) {
    this.storage.get('authToken').then((storedToken: string | undefined) => {
      this.storage.get('userInfo').then((storedUserInfo: AppUserInformationDef | undefined) => {
        if (storedUserInfo) {
          this.userInformation$.next(storedUserInfo);
        }
        if (storedToken) {
          this.authToken$.next(storedToken);
        }
        this.userInformation$.subscribe({
          next: (userInfo: AppUserInformationDef) => {
            this.storage.set('userInfo', userInfo);
            this._checkRoute();
          }
        });
        this.authToken$.subscribe({
          next: (token: string) => {
            this.storage.set('authToken', token || '');
            this._checkRoute();
          }
        });
        this.initialized$.subscribe({
          next: (value: boolean) => {
            if (value) {
              this._checkRoute();
            }
          }
        });
        this.initialized$.next(true);
      });
    });
  }

  _setAuthToken(token: string) {
    this.authToken$.next(token || '');
  }

  _checkRoute() {
    if (!this.initialized$.value) {
      return;
    }
    const isInDashboard: boolean = window.location.href.indexOf('dashboard') >= 0;
    // Add here any public route to avoid redirect when logged in
    const isInPublicRoute: boolean = window.location.pathname.indexOf('sheet-view') >= 0 || window.location.pathname.indexOf('ticket-view') >= 0;
    const isLoggedIn = (this.userInformation$.value && this.userInformation$.value.user && this.userInformation$.value.user.id > 0) &&
      (!isApp || (isApp && this.authToken$.value.length > 0));
    if (isLoggedIn && !isInDashboard && !isInPublicRoute) {
      this.router.navigate([loggedIngNavigationDefault], { replaceUrl: true });
    } else if (!isLoggedIn && isInDashboard) {
      this.router.navigate([loggedOutgNavigationDefault], { replaceUrl: true });
    }
  }

  _get(endPoint: string, data?: any, options?: any): Observable<any> {
    // tslint:disable-next-line:variable-name
    let authHeaders: HttpHeaders = new HttpHeaders();
    if (isApp) {
      authHeaders = authHeaders.set('apiaf', 'app');
    } else {
      authHeaders = authHeaders.set('apiaf', 'web');
    }
    return ((_endPoint: string, _data: any, _authHeaders: HttpHeaders, suscription) => {
      return new Observable((subscriber: Subscriber<any>) => {
        suscription = this.initialized$.subscribe({
          next: (value: boolean) => {
            if (value === true) {
              setTimeout(() => suscription.unsubscribe(), 10);
              if (isApp) {
                _authHeaders = _authHeaders.set('authtoken', this.authToken$.value);
              }
              this.http.get(_endPoint, _data, _authHeaders, options).pipe(map((response: any) => {
                if (_endPoint === 'account/login.json' || _endPoint === 'account/check_login.json' || _endPoint === 'account/signup.json' || _endPoint === 'account/login_token.json') {
                  if (response.app_tokens) {
                    this.authToken$.next(response.app_tokens.oauth_token || '');
                    delete response.app_tokens;
                  }
                  this.userInformation$.next(response);
                }
                return response;
              }),
                catchError((error: HttpErrorResponse) => {
                  if (error instanceof Error && error.message === 'YOU_ARE_NOT_LOGGED_IN') {
                    this._resetAuthState();
                  }
                  return throwError(error);
                }))
                .toPromise()
                .then((result: any) => subscriber.next(result))
                .catch((error: any) => subscriber.error(error))
                .finally(() => subscriber.complete());
            }
          }
        });
      });
    })(endPoint, data, authHeaders, null);
  }

  _post(endPoint: string, data?: any): Observable<any> {
    let authHeaders: HttpHeaders = new HttpHeaders();
    if (isApp) {
      authHeaders = authHeaders.set('apiaf', 'app');
    } else {
      authHeaders = authHeaders.set('apiaf', 'web');
    }
    // tslint:disable-next-line:variable-name
    return ((_endPoint: string, _data: any, _authHeaders: HttpHeaders, suscription) => {
      return new Observable((subscriber: Subscriber<any>) => {
        suscription = this.initialized$.subscribe({
          next: (value: boolean) => {
            if (value === true) {
              setTimeout(() => suscription.unsubscribe(), 10);
              if (isApp) {
                _authHeaders = _authHeaders.set('authtoken', this.authToken$.value);
              }
              this.http.post(_endPoint, _data, _authHeaders).pipe(map((response: any) => {
                if (_endPoint === 'account/login.json' || _endPoint === 'account/check_login.json' || _endPoint === 'account/signup.json' || _endPoint === 'account/login_token.json') {
                  if (response.app_tokens) {
                    this.authToken$.next(response.app_tokens.oauth_token || '');
                    delete response.app_tokens;
                  }
                  this.userInformation$.next(response);
                }
                return response;
              }),
                catchError((error: HttpErrorResponse) => {
                  if (error instanceof Error && error.message === 'YOU_ARE_NOT_LOGGED_IN') {
                    this.router.navigate([loggedOutgNavigationDefault], { replaceUrl: true });
                    this._resetAuthState();
                  }
                  return throwError(error);
                }))
                .toPromise()
                .then((result: any) => subscriber.next(result))
                .catch((error: any) => subscriber.error(error))
                .finally(() => subscriber.complete());
            }
          }
        });
      });
    })(endPoint, data, authHeaders, null);

  }

  _logout(): Promise<boolean> {
    return new Promise((resolve: any, reject: any): void => {
      this._post('account/logout.json').toPromise().then((response: any) => {
        if (response === true) {
          this._resetAuthState();
          resolve(true);
        } else {
          reject(false);
        }
      }).catch((error: HttpErrorResponse) => {
        reject(error);
      });
    });
  }

  _checkLogin(): Promise<AppUserInformationDef> {
    return this._post('account/check_login.json').toPromise();
  }

  _loginWithToken(data: { token: string }): Promise<AppUserInformationDef> {
    return this._post('account/login_token.json', data).toPromise();
  }

  _getUserInformation(): Observable<AppUserInformationDef> {
    return this.userInformation$.asObservable();
  }

  _authState(): Observable<any> {
    return new Observable((subscrbr: Subscriber<{ token: string, user: AppUserInformationDef }>) => {
      ((subscriber, suscription) => {
        suscription = this.initialized$.subscribe({
          next: (value: boolean) => {
            if (value) {
              setTimeout(() => suscription.unsubscribe(), 10);
              subscriber.next({ token: this.authToken$.value, user: this.userInformation$.value });
              subscriber.complete();
              this._checkLogin().then((result: any) => { }).catch((error: Error) => { });
            }
          }
        });
      })(subscrbr, null);
    });
  }

  private _resetAuthState() {
    this.userInformation$.next(this.loggedOutUserInfo);
    this.authToken$.next('');
  }

  get initialized() {
    return this.initialized$.asObservable();
  }

}
