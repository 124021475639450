import { Injectable } from '@angular/core';

import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  async set(key: string, value: any) {
    await Storage.set({
      key,
      value: JSON.stringify(value)
    });
  }

  async get(key: string) {
    const { value } = await Storage.get({ key });
    return (value ? JSON.parse(value) : undefined);
  }

  async remove(key: string) {
    await Storage.remove({ key });
  }

  async keys() {
    const { keys } = await Storage.keys();
    return keys;
  }

  async clear() {
    await Storage.clear();
  }
}
