import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class DateTime {

  constructor(private translate: TranslateService) { }

  formatDate(date: any) {
    let retVal = '';
    if (date && date > 0) {
      date = (new Date(date));
      retVal = date.getDate() + ' ' + date.toLocaleString(this.translate.currentLang, { month: 'long' }) + ' ' + date.getFullYear();
    }
    return retVal;
  }

  formatDateDay(date: any) {
    let retVal = '';
    if (date && date > 0) {
      date = (new Date(date));
      retVal = date.getDate();
    }
    return retVal;
  }

  formatDateMonth(date: any) {
    let retVal = '';
    if (date && date > 0) {
      date = (new Date(date));
      retVal = date.toLocaleString(this.translate.currentLang, { month: 'short' });
    }
    return retVal;
  }

  formatDateHour(date: any) {
    let retVal = '';
    if (date && date > 0) {
      date = (new Date(date)) as Date;
      let hours = date.getHours();
      const ampm = hours >= 12 ? 'pm' : 'am';
      const minutes = date.getMinutes();
      hours = hours % 12;
      hours = hours ? hours : 12;
      retVal = hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ' ' + ampm;
    }
    return retVal;
  }

  formatTime(date: any) {
    let retVal = '';
    if (date && date > 0) {
      date = (new Date(date)) as Date;
      retVal = date.getHours().toString() + ':' + date.getMinutes().toString();
    }
    return retVal;
  }

  formatShortDate(date: any) {
    let retVal = '';
    if (date && date > 0) {
      retVal = (new Date(date)).toLocaleDateString(this.translate.currentLang);
    }
    return retVal;
  }

  formatYear(date: any) {
    let retVal = '';
    if (date && date > 0) {
      retVal = (new Date(date)).getUTCFullYear().toString();
    }
    return retVal;
  }

  formatFullDateTime(date: any) {
    let retVal = '';
    if (date && date > 0) {
      date = (new Date(date));
      retVal = date.toLocaleString(this.translate.currentLang);
    }
    return retVal;
  }

  formatDateMonthLong(date: any) {
    let retVal = '';
    if (date && date > 0) {
      date = (new Date(date));
      retVal = date.toLocaleString(this.translate.currentLang, { month: 'long' });
    }
    return retVal;
  }


}
