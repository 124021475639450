import { Injectable } from '@angular/core';
import { UserAPIService } from '../controllers/user-api.service';
import { ClientAPIService } from '../controllers/client-api.service';
import { SystemAPIService } from '../controllers/system-api.service';
import { AdminApiService } from '../controllers/admin-api.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    public user: UserAPIService,
    public client: ClientAPIService,
    public admin: AdminApiService,
    public system: SystemAPIService,
    public auth: AuthService
  ) { }

  get initialized() {
    return this.auth.initialized;
  }

}
