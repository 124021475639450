import { Injectable } from '@angular/core';
import { AuthService } from '../core/auth.service';


@Injectable({
  providedIn: 'root'
})
export class AdminApiService {
  /**
   * TODO
   */
  constructor(
  ) { }
}

