export const TICKET_CONFIG = {
    form: {
        fields: []
    },
    details: {
        fields: []
    },
    embeded: {
        fields: []
    },
    list: {
        baseCondition: { id: { gt: 0 } },
        columns: [
            {
                id: 'id',
                orderColName: 'id',
                header: { label: 'Id' },
                visible: true,
                size: 12,
                formatFnConfig: [{
                    componentName: 'ion-label',
                    props: {
                        text: {
                            formatters: [{ functionName: 'resolve', params: { path: 'public_id' } }]
                        },
                        color: { value: 'dark' }
                    }
                }]
            },
            {
                id: 'process',
                orderColName: 'process',
                header: { label: 'Proceso' },
                visible: true,
                size: 18,
                formatFnConfig: [
                    {
                        componentName: 'ion-label',
                        props: {
                            text: {
                                formatters: [{ functionName: 'resolve', params: { path: 'process.name' } }]
                            },
                            color: { value: 'primary' }
                        }
                    },
                    {
                        componentName: 'ion-label',
                        props: {
                            text: {
                                formatters: [{ functionName: 'resolve', params: { path: 'stage.definition.name' } }]
                            },
                            color: { value: 'medium' }
                        }
                    }
                ]
            },
            {
                id: 'client',
                // orderColName: 'client',
                header: { label: 'Cliente' },
                visible: true,
                size: 25,
                formatFnConfig: [
                    {
                        componentName: 'ion-label',
                        props: {
                            text: {
                                formatters: [{ functionName: 'resolve', params: { path: 'entityFrom.attrs.alias' } }]
                            },
                            color: { value: 'primary' }
                        }
                    },
                    {
                        componentName: 'ion-label',
                        props: {
                            text: {
                                formatters: [{ functionName: 'resolve', params: { path: 'entityFrom.attrs.taxId' } }]
                            },
                            color: { value: 'medium' }
                        }
                    }
                ]
            },
            {
                id: 'subject',
                // orderColName: 'client',
                header: { label: 'Asunto' },
                visible: true,
                size: 25,
                formatFnConfig: [
                    {
                        componentName: 'ion-label',
                        props: {
                            text: {
                                formatters: [{ functionName: 'resolve', params: { path: 'subject' } }]
                            },
                            color: { value: 'primary' }
                        }
                    }
                ]
            },
            {
                id: 'createDate',
                // orderColName: 'client',
                header: { label: 'Fecha' },
                visible: true,
                size: 10,
                formatFnConfig: [
                    {
                        componentName: 'ion-label',
                        props: {
                            text: {
                                formatters: [
                                    { functionName: 'resolve', params: { path: 'create.date' } },
                                    { functionName: 'datetime', params: { format: 'short_date' } }
                                ]
                            },
                            color: { value: 'primary' }
                        }
                    }
                ]
            },
            {
                id: 'status',
                header: { label: 'Estado' },
                visible: true,
                size: 10,
                formatFnConfig: [
                    {
                        componentName: 'ion-label',
                        props: {
                            text: {
                                formatters: [
                                    { functionName: 'resolve', params: { path: 'status.definition.name' } }
                                ]
                            },
                            color: { value: 'primary' }
                        }
                    }
                ]
            },
            {
                id: 'owner',
                header: { label: 'Propietario' },
                visible: false,
                size: 10,
                formatFnConfig: [
                    {
                        componentName: 'ion-label',
                        props: {
                            text: {
                                formatters: [
                                    { functionName: 'resolve', params: { path: 'owner.client_account.profile.name' } },
                                ]
                            },
                            color: { value: 'primary' }
                        }
                    }
                ]
            },
            {
                id: 'assignee',
                header: { label: 'Asignado' },
                visible: false,
                size: 10,
                formatFnConfig: [
                    {
                        componentName: 'ion-label',
                        props: {
                            text: {
                                formatters: [
                                    { functionName: 'resolve', params: { path: 'assignee.client_account.profile.name' } },
                                ]
                            },
                            color: { value: 'medium' }
                        }
                    }
                ]
            },
            {
                id: 'details',
                header: { label: 'Detalle' },
                visible: false,
                size: 10,
                formatFnConfig: [
                    {
                        componentName: 'ion-label',
                        props: {
                            text: {
                                formatters: [
                                    { functionName: 'resolve', params: { path: 'details' } },
                                ]
                            },
                            color: { value: 'medium' }
                        }
                    }
                ]
            },
            {
                id: 'entityTo',
                header: { label: 'Destinatario' },
                visible: false,
                size: 10,
                formatFnConfig: [
                    {
                        componentName: 'ion-label',
                        props: {
                            text: {
                                formatters: [
                                    { functionName: 'resolve', params: { path: 'entityTo.attrs.alias' } },
                                ]
                            },
                            color: { value: 'medium' }
                        }
                    }
                ]
            },
            {
                id: 'attachments',
                header: { label: 'Adjuntos' },
                visible: false,
                size: 10,
                formatFnConfig: [
                    {
                        componentName: 'ion-label',
                        props: {
                            text: {
                                formatters: [
                                    { functionName: 'resolve', params: { path: 'attachments.length' } },
                                ]
                            },
                            color: { value: 'medium' }
                        }
                    }
                ]
            }
        ]
    }

};
