// import { CLIENT_CONFIG } from "./items/client";
// import { ENTITY_CONFIG } from "./items/entity";
// import { PRODUCT_CONFIG } from "./items/product";
import { TICKET_CONFIG } from "./items/ticket";

export const ORGANIZATION_CONFIG = {
    ticket: TICKET_CONFIG,
    // product: PRODUCT_CONFIG,
    // entity: ENTITY_CONFIG,
    // client: CLIENT_CONFIG,
};

export const CUSTOM_CONFIG = {
    ticket: {},
    // client: {},
    // entity: {},
    // product: {}
};
