import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { loggedIngNavigationDefault, loggedOutgNavigationDefault, pedingLoggedInUrl, userStateRouting } from 'src/environments/environment';
import { AppUserInformationDef } from './api_interfaces/core';
import { SystemAPIService } from './services/api/controllers/system-api.service';
import { ApiService } from './services/api/core/api.service';
import { LanguageService } from './services/language/language.service';
import { CustomDisplayService } from './services/utils/custom-display.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  
  initialized = false;

  constructor(
    private platform: Platform,
    private language: LanguageService,
    private titleService: Title,
    private translate: TranslateService,
    private customDisplay: CustomDisplayService,
    private router: Router,
    private api: ApiService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.language.setInitialAppLanguage().then(() => {
        this.translate.get('system.browserTitleGuest').toPromise().then(value => this.titleService.setTitle(value));
      });

      this.api.initialized.subscribe({
        next: (value: boolean) => {
          if (value) {
            this.api.system.initVersionChecker();
            this.api.user.checkLogin();
            if (userStateRouting) {
              this.api.user.getInformation().subscribe({
                next: (userInfo: AppUserInformationDef) => {
                  if (userInfo?.user?.id) {
                    if (userInfo?.organization_entailments?.length) {
                      // if (!/^\/dashboard\/staff\/tickets\/details\/\d*/gm.test(window.location.pathname)) {
                      //   this.router.navigate([loggedIngNavigationDefault], { replaceUrl: true });
                      // }
                    } else {
                      this.router.navigate([pedingLoggedInUrl], { replaceUrl: true });
                    }
                  } else {
                    if (
                      this.initialized
                      && window.location.pathname.indexOf('login') < 0
                      && window.location.pathname.indexOf('signup') < 0
                      && window.location.pathname.indexOf('password-reset') < 0
                      && window.location.pathname.indexOf('terms') < 0
                      && window.location.pathname.indexOf('privacy') < 0
                      && window.location.pathname.indexOf('cookies') < 0
                      && window.location.pathname.indexOf('sheet-view') < 0
                      && window.location.pathname.indexOf('ticket-view') < 0
                    ) {
                      this.router.navigate([loggedOutgNavigationDefault], { replaceUrl: true, queryParamsHandling: 'preserve' });
                    }
                  }

                }
              });
            }
            this.initialized = true;
          }
        }
      });
    });
  }

}
