// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

export const AppTypeDef = 'website_ticketing';
export const apiPathUrl = 'https:///wn.yblnkio.com/api/';
export const apiWithCredentials = false;

export const isApp = true;

export const supportedLanguages = [
  { text: 'Español', value: 'es-ES', img: '' }/*,
  { text: 'English', value: 'en-US', img: '' }*/
];

export const GoogleRecaptcha = {
  useOnLogin: true,
  SiteKey: '6LfdHawUAAAAAPyE6aAZHit8Pd7s4hYx5MpkXPGT'
};

export const defaultLanguage = 'es-ES';

export const userStateRouting = true;

export const loggedIngNavigationDefault = '/dashboard';
export const loggedOutgNavigationDefault = '/login';
export const pedingLoggedInUrl = `/dashboard/pending`;
export const wayanaSalesUrl = 'https://wnsd.yblnkio.com';

export const defaultProfileImage = '/assets/avatar.svg';
export const defaultNoImage = "/assets/image/not-image.png";

export const googleServicesApiKeys = {
  drive: {
    filePicket: {
      developerKey: 'AIzaSyBjIxTnxKygv4RCxnDPmsEc-VnUKojepoM',
      // The Client ID obtained from the Google API Console. Replace with your own Client ID.
      clientId: '318342922631-h9qb01k3qug301n5ind1sa2nig42ld2v.apps.googleusercontent.com',
      // Replace with your own project number from console.developers.google.com.
      // See "Project number" under "IAM & Admin" > "Settings"
      appId: '318342922631'
    }
  }
};

export const imageExtensionsAccept = 'image/png, image/jpeg, image/jpg, image/svg+xml, image/webp';

export const fileExtensionsAccept = 'image/png, image/jpeg, image/jpg, image/svg+xml, image/webp, video/mpeg, video/x-msvideo, application/pdf, video/ogg, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/zip, application/x-rar-compressed';

export const entityTypeDef = {
  main: 1,
  department: 2,
  customer: 3,
  provider: 4,
  branch_office: 5
};

export const processStageStatusDef = {
  idle: 1,
  started: 2,
  done: 3,
  skipped: 4,
  blocked: 5,
  unblocked: 6,
  cancelled: 7
};

export const ticketCommentsPrivacyDef = {
  organization_only: 'organization_only',
  public: 'public'
};

export const systemItemTypesDef = {
  ORGANIZATION: 2,
  ENTITY: 3,
  ROLE: 4,
  USER: 5,
  PROCESS: 6,
  PRODUCT: 7,
  EQUIPMENT: 8,
  TICKET: 10,
  FILE: 11
};

export const systemScopeTypesDef = {
  PUBLIC: 1,
  ORGANIZATION: 2,
  ENTITY: 3,
  CREATOR: 4,
  PARENT_ENTITY: 5,
  ASSIGNEE: 6,
  OWNER: 7
};

export const systemActionLevelsDef = {
  NONE: 0,
  VIEW: 4,
  CREATE: 8,
  MODIFY: 12,
  ADMIN: 16
};

export const systemNotificationActionsDef = {
  new_ticket: 1,
  change_stage: 2,
  change_assignee: 3,
  change_status: 4,
  new_user: 5
};

export const systemNotificationTypesDef = {
  ticket: 1,
  stage: 2,
  assignee: 3,
  status: 4,
  user: 5
};

export const systemOverviewDataViewDef = {
  area_chart: 1,
  column_chart: 2,
  combo_chart: 3,
  bar_chart: 4,
  line_chart: 5,
  pie_chart: 6,
  table: 7,
  indicator: 8
};

export const chartColors = [
  '#D81B60',
  '#0353A4',
  '#68CF79',
  '#DE7E28',
  '#5054B8',
  '#F5B300',
  '#00A8E8',
  '#8E24AA',
  '#3A506B',
  '#F60F94',
  '#606C38',
  '#502419',
  '#B388EB',
  '#64687C',
  '#8F2D56',
  '#197993'
];

export const lightChartColors = [
  '#EFA3BF',
  '#BDD4F1',
  '#9CDCA8',
  '#FABA82',
  '#A3A5DA',
  '#F7E241',
  '#84D2F6',
  '#D1A6DD',
  '#AFB7C4',
  '#F46DBB',
  '#9FB162',
  '#B4A19C',
  '#E0CAFC',
  '#C9CACE',
  '#BA869C',
  '#A2C9D3'
];
