import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IAPICustomUIConfigDef, AppOrganizationUIConfigDef, AppUserInformationDef } from 'src/app/api_interfaces/core';
import { DateTime } from 'src/app/components/utils/date-time/date-time';
import { ApiService } from '../api/core/api.service';
import { StorageService } from './storage.service';
import { CUSTOM_CONFIG, ORGANIZATION_CONFIG } from './ui-config/ui-config';

@Injectable({
  providedIn: 'root'
})
export class CustomDisplayService {

  renderComponents = {};

  formatFunctions = {
    self: (data) => data,
    resolve: (value, extraParams, acc) => {
      const reduce = (path) => {
        return path.split(".").reduce((prev, curr) => {
          return prev?.[curr] || '';
        }, value || self);
      };
      const pth = extraParams?.path || extraParams;
      return Array.isArray(pth) ? pth.map(p => reduce(p)) : reduce(pth);
    },
    datetime: (date, extraParams) => {
      switch (extraParams?.format) {
        case 'date':
          return this.datetime.formatDate(date);
        case 'day':
          return this.datetime.formatDateDay(date);
        case 'month':
          return this.datetime.formatDateMonth(date);
        case 'month_long':
          return this.datetime.formatDateMonthLong(date);
        case 'hour':
          return this.datetime.formatDateDay(date);
        case 'short_date':
          return this.datetime.formatShortDate(date);
        case 'full':
        default:
          return this.datetime.formatFullDateTime(date);
      }
    },
    translate: (key, extraParams) => {
      const prefix = (extraParams?.prefix || false) ? extraParams?.prefix + '.' : '';
      return this.translate.instant(prefix + key);
    },
    concat: (value, extraParams) => {
      return `${extraParams?.prefix || ''}${value}${extraParams?.suffix || ''}`;
    },
    address: (value) => {
      return `${value.street} ${value.street_number}, ${value.city}, ${value.state_region}. ${value.country}`;
    },
    join: (value, extraParams) => {
      return value.join(extraParams?.separator || ' ');
    },
    split: (value, extraParams) => {
      return value.split(extraParams?.separator || ' ');
    }
  };

  organizationConfig: AppOrganizationUIConfigDef;
  customConfig: IAPICustomUIConfigDef;

  constructor(
    private datetime: DateTime,
    private translate: TranslateService,
    private storage: StorageService,
    private api: ApiService
  ) {
    /* RSCUserAccount.GetCurrentUserSync().Subscribe({
      next: (user: RSCUserAccount) => {
        if (user.IsLoggedIn() && user.HasEntailments()) {
          this.initialize();
        } else {
          this.organizationConfig = null;
          this.customConfig = null;
        }
      }
    }); */
    this.api.user.getInformation().subscribe({
      next: (userInfo: AppUserInformationDef) => {
        if (userInfo.user.id && userInfo.organization_entailments.length) {
          this.initialize();
        } else {
          this.organizationConfig = null;
          this.customConfig = null;
        }
      }
    });
  }

  initialize() {
    this.loadOrganizationConfig();
    this.loadCustomConfig();
  }

  loadOrganizationConfig() {
    // TODO load from API or LocalStorage
    this.organizationConfig = ORGANIZATION_CONFIG;
  }

  async loadCustomConfig() {
    this.customConfig = await this.storage.get('userUIConfig') || CUSTOM_CONFIG;
  }

  // saveOrganizationConfig() { }

  saveCustomConfig() {
    this.storage.set('userUIConfig', this.customConfig);
  }

  saveCustomListConfig(itemTypeName, config) {
    this.customConfig[itemTypeName].list = config;
    this.saveCustomConfig();
  }

  saveCustomFormConfig(itemTypeName, config) {
    this.customConfig[itemTypeName].form = config;
    this.saveCustomConfig();
  }

  saveCustomDetailsConfig(itemTypeName, config) {
    this.customConfig[itemTypeName].details = config;
    this.saveCustomConfig();
  }

  saveCustomEmbededConfig(itemTypeName, config) {
    this.customConfig[itemTypeName].embeded = config;
    this.saveCustomConfig();
  }

  getFormConfig(itemName: string, parentRef) {
    const settings = this.organizationConfig?.[itemName]?.form;
    // ADD any parse code here
    return settings;
  }

  getCustomFormConfig(itemName: string) {
    const settings = this.customConfig?.[itemName]?.form;
    // ADD any parse code here
    return settings;
  }

  getDetailsConfig(itemName: string, parentRef) {
    const settings = this.organizationConfig?.[itemName]?.details;
    // ADD any parse code here
    return settings;
  }

  getCustomDetailsConfig(itemName: string) {
    const settings = this.customConfig?.[itemName]?.details;
    // ADD any parse code here
    return settings;
  }

  getEmbededConfig(itemName: string, parentRef) {
    const settings = this.organizationConfig?.[itemName]?.embeded;
    // ADD any parse code here
    return settings;
  }

  getCustomEmbededConfig(itemName: string) {
    const settings = this.customConfig?.[itemName]?.embeded;
    // ADD any parse code here
    return settings;
  }

  getCustomListConfig(itemName: string) {
    const settings = this.customConfig?.[itemName]?.list;
    // ADD any parse code here
    return settings;
  }

  getKanbanConfig(itemName: string) {
    const settings = this.organizationConfig?.[itemName]?.kanban;
    // ADD any parse code here
    return settings;
  }

  getListConfig(itemName: string, parentRef) {
    const settings = JSON.parse(JSON.stringify(this.organizationConfig?.[itemName]?.list));
    settings.columns = settings.columns.map((column, i) => {
      const formatFn = this._parseFormatFunction(column.formatFnConfig, parentRef);

      const retVal = JSON.parse(JSON.stringify(column));
      delete retVal.formatFnConfig;
      retVal.formatFn = formatFn;
      return retVal;
    });

    return settings;
  }

  _parseFormatFunction(formatFnConfig, parentRef?) {
    return (record) => (Array.isArray(formatFnConfig) ? formatFnConfig : [formatFnConfig]).map(config => {
      const getProps = (rec) => {
        const props: any = {};

        const reduceFn = (prev: any, curr: any) => {
          return this.formatFunctions[curr.functionName]?.(prev, curr.params, rec)/*  || prev */;
        };

        Object.keys(config?.props || {}).forEach((k) => {
          props[k] = Array.isArray(config.props[k]) ?
            config.props[k].map(prop => prop.value || (prop.formatters as []).reduce(reduceFn, rec)) :
            config.props[k].value || (config.props[k].formatters as []).reduce(reduceFn, rec);
        });
        return props;
      };

      const listeners: any = {};
      Object.keys(config?.listeners || {}).forEach((k) => {
        if (parentRef[config.listeners[k]]) {
          listeners[k] = (data) => parentRef[config.listeners[k]](data);
        }
      });

      return {
        component: this.renderComponents[config?.componentName] || config?.componentName,
        props: getProps(record),
        listeners
      };

    });
  }

  getCardConfig(itemName, parentRef?) {
    const settings = JSON.parse(JSON.stringify(this.organizationConfig?.[itemName]?.card));
    return this._parseFormatFunctionConfig(settings, parentRef);
  }

  _parseFormatFunctionConfig(settings, parentRef?) {
    const retVal: any = { props: {}, listeners: {} };
    // tslint:disable-next-line: forin
    for (const key in settings.props) {
      const formatFn = (record) => {
        const prop: any = {};
        for (const k in settings.props[key]) {
          if (k === 'formatters') {
            prop.value = settings.props[key][k].reduce((prev: any, curr: any) => {
              return this.formatFunctions[curr.functionName]?.(prev, curr.params, record);
            }, record);
          } else {
            prop[k] = settings.props[key][k];
          }
        }
        return prop;
      };
      retVal.props[key] = formatFn;
    }

    Object.keys(settings?.listeners || {}).forEach((k) => {
      if (parentRef?.[settings.listeners[k]]) {
        retVal.listeners[k] = (data) => parentRef[settings.listeners[k]](data);
      }
    });

    return retVal;
  }

}
